<template>
  <div
    :class="[
      desktopCollapsed ? 'page-container--collapsed' : '',
      'page-container'
    ]"
  >
    <RetailMenu
      v-if="permission !== null"
      active-menu="Capabilities Backoffice"
      :env="environment"
      :permission="permission"
      @toggle-mobile-sidebar="mobileCollapsed = $event"
      @toggle-desktop-sidebar="desktopCollapsed = $event"
    />
    <div
      :class="[
        mobileCollapsed ? '' : 'page-component--collapsed',
        'page-component'
      ]"
    >
      <Toaster
        :hidden="hidden"
        :icon="icon"
        :appearance="appearance"
        :message="message"
        :close-handler="handleCloseToast"
      />
      <TypeIdModal :show="showInfoModal" @did-cancel="showInfoModal = false" />
      <div v-if="showRestrictMessage">
        <EmptyContent
          illustration="character-computer-headset"
          :title="$t('APP.ACCESS_DENIED')"
          :description="$t('APP.ACCESS_DENIED_DESC')"
        />
        <div class="guest-actions">
          <pn-button appearance="dark" @click.prevent="login">
            {{ $t('APP.LOGIN') }}
          </pn-button>
          <pn-button appearance="light" @click.prevent="goToLandingPage">
            {{ $t('APP.BACK_TO_LANDING') }}
          </pn-button>
        </div>
      </div>
      <div v-else>
        <CapabilityHeader
          :loading="loading"
          :filter-ssid="currentSsidSearch"
          :component-key="componentKey"
          @did-search="handleSearchWithSsid"
          @did-click-help="showInfoModal = true"
        />
        <router-view :key="componentKey" />
        <br />
        <pn-footer></pn-footer>
        <p class="metadata">
          {{ $t('APP.CURRENT_VERSION') }}: {{ appVersion }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import { version } from '../package.json'

import TypeIdModal from '@/components/TypeIdModal'
import CapabilityHeader from '@/components/CapabilityHeader.vue'
import { getHomePage } from '@postnord/retail-backoffice-components/dist'

export default {
  components: {
    TypeIdModal,
    CapabilityHeader
  },
  data() {
    return {
      showRestrictMessage: false,
      mobileCollapsed: true,
      desktopCollapsed: false,
      showInfoModal: false,
      componentKey: 0,
      currentSsidSearch: ''
    }
  },
  computed: {
    ...mapState(['lang']),
    ...mapState('toaster', [
      'hidden',
      'icon',
      'appearance',
      'message',
      'closable'
    ]),
    ...mapState('capability', ['permission', 'loading']),
    ...mapGetters(['isLoggedIn']),
    isTopbarLoggedIn() {
      return window.pnTopbar.isLoggedIn
    },
    loginValid() {
      let valid = true
      if (!this.isLoggedIn) {
        valid = false
      }

      if (this.$store.state.topbar.tokenExpires < 1) {
        valid = false
      }

      const expires =
        this.$store?.state?.topbar?.cookies['IAM_access_token_expires']
      const timeRemaining = this.$store.state.topbar.tokenExpires
      if (expires - timeRemaining > new Date().getTime()) {
        valid = false
      }

      return valid
    },
    environment() {
      return process.env.NODE_ENV
    },
    appVersion() {
      return version
    }
  },
  watch: {
    loginValid(valid) {
      if (!valid) {
        this.goToLandingPage()
      }
    },
    permission(value) {
      this.showRestrictMessage = !this.loading && value < 3
    },
    lang() {
      this.componentKey += 1
    },
    $route(val) {
      if (val.path === '/capabilities') {
        this.currentSsidSearch = ''
      }
    }
  },
  mounted() {
    if (!window['Cypress']) {
      if (window.pnTopbar?.hasLoaded) {
        this.checkIfLoggedIn()
      } else {
        window.addEventListener('topbarLoaded', () => this.checkIfLoggedIn())
      }
    } else {
      // Using cypress
      window.store = this.$store
      window.store.state.capability.permission = 3
    }
  },
  methods: {
    ...mapActions('capability', [
      'getPermissions',
      'getServicepointCapabilities'
    ]),
    checkIfLoggedIn() {
      if (this.isLoggedIn || this.isTopbarLoggedIn) {
        this.setInitialStoreState()
        this.getPermissions()
      } else {
        this.goToLandingPage()
      }
    },
    goToLandingPage() {
      if (this.environment === 'development') {
        window.pnTopbar.login()
      } else {
        window.location.href = getHomePage(this.environment)
      }
    },
    setInitialStoreState() {
      this.$store.dispatch('setLocalizationState')
      this.$store.dispatch('setAccessToken')
      this.$store.dispatch('setTokenExpires')
    },
    handleCloseToast() {
      this.$store.dispatch('toaster/hideToaster')
    },
    handleSearchWithSsid(val) {
      if (
        this.$route.name === 'Servicepoint' &&
        val === this.$route.params.id
      ) {
        this.getServicepointCapabilities(val)
      } else {
        this.$router.push({
          name: 'Servicepoint',
          params: { id: val }
        })
      }
      this.currentSsidSearch = val
    }
  }
}
</script>

<style lang="scss">
.page-container {
  display: block;
}

.page-component {
  width: 100%;

  &--collapsed {
    display: none;
  }
}

@media (min-width: variables.$breakpoint-lg-tablet) {
  .page-container {
    transition: 0.2s ease-in-out;
    margin-left: 19em;
    display: flex;

    &--collapsed {
      margin-left: 3.8em;
    }
  }
}
</style>
